<template>
  <b-row class="match-height">
    <b-col cols="12" v-if="can('show/manajemen-pegawai')">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">NIPPOS</h6>
            <p class="card-text mb-25">
              {{ model.nippos }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Nama</h6>
            <p class="card-text mb-25">
              {{ model.nama }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Kantor</h6>
            <p class="card-text mb-25">
              {{ model.namaktr }} - {{ model.nopend }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Kode Jabatan</h6>
            <p class="card-text mb-25">
              {{ model.jabatan }}
            </p>
          </b-col>
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Jabatan</h6>
            <p class="card-text mb-25">
              {{ model.descjabatan }}
            </p>
          </b-col>
          <!-- <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Bagian</h6>
            <p class="card-text mb-25">
              {{ model.namabagian }}
            </p>
          </b-col> -->
          <b-col cols="12" xl="6" class="pl-2 mb-2">
            <h6 class="mb-25">Bagian / Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ model.namabagian }}
            </p>
          </b-col>
        </b-row>
        <b-card-footer>
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'manajemen-pegawai' }"
          >
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
    <b-col v-else>
      <b-card>
        <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
          <div class="w-100 text-center">
            <h2 class="mb-1">Tidak Memiliki Akses</h2>
            <p class="mb-2">Anda tidak memiliki akses pada halaman ini</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import { BRow, BCol, BButton, BCard, BCardFooter } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },
  data() {
    return {
      name: 'manajemen-pegawai',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/employees',
        redirect: 'manajemen-pegawai',
      },
      model: [],
      confirmPass: null,
      messages: '',
    }
  },
  mounted() {
    let _ = this
    _.getData()
  },
  methods: {
    getData() {
      let _ = this
      axios.get(_.config.api + '/' + this.$route.params.id).then(response => {
        let _ = this
        _.model = response.data.data[0]
      })
    },
  },
}
</script>
